// App.js
import React from "react";
import { useLocation } from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./styles/global.css";
import Routing from "./Routing";

const App = () => {
  const location = useLocation();

  return (
    <AuthProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/*   {location.pathname.includes("exclusives") || 
        location.pathname.includes("partners") ? ( */}
        <Routing />
        {/*} ) : (
          <Routes />
        )} */}
      </LocalizationProvider>
    </AuthProvider>
  );
};

export default App;
