import Box from "@mui/material/Box";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  ReferenceLine,
} from "recharts";
import {
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LOCALSTORAGE_KEY_TOKEN, useAuth } from "./AuthContext";
import { useEffect, useState } from "react";
import API from "./config/API";
import Speedometer from "react-d3-speedometer";
import { useToast } from "./components/Toast";

export default function Home() {
  const {
    isLoggedIn,
    userId,
    token,
    userType,
    partnerCode,
    apiUrl,
    isLoading,
    setLoader,
    unsetLoader,
  } = useAuth();
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState(null);
  const [lineChartData, setLineChartData] = useState([]);
  const [lineChartRevenueData, setLineChartRevenueData] = useState([]);
  const [totalLeadsIn, setTotalLeadsIn] = useState(0);
  const [totalLeadsOut, setTotalLeadsOut] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const toaster = useToast();

  useEffect(() => {
    fetchData();
  }, [isLoggedIn]);

  const fetchData = async () => {
    //const localStorageData = localStorage.getItem(LOCALSTORAGE_KEY_TOKEN);
    //const userData = JSON.parse(localStorageData);
    //if (!userData) return;
    try {
      setLoader();
      if (userType == "admin") {
        const response4 = await API.get(`/home_titles`);
        console.log(response4.data.data);
        setTotalLeadsIn(response4.data.data.total_leads_in);
        setTotalLeadsOut(response4.data.data.total_leads_out);
        setTotalRevenue(response4.data.data.total_revenue);

        const response = await API.get(`/home_barchart`);
        setData(response.data.data);

        const response3 = await API.get(`/home_leads_trend`);
        const randomLineChartData = Array.from({ length: 12 }, (_, index) => ({
          name: index + 1, // Month number
          value: 2, //Math.floor(Math.random() * 1000)+10, // Random value
        }));
        const response5 = await API.get(`/home_current_month_revenue_trend`);

        console.log(randomLineChartData);
        console.log(response3.data.data);
        console.log(response3.data.data);
        setLineChartData(response3.data.data);
        setLineChartRevenueData(response5.data.data);

        unsetLoader();
      }
    } catch (error) {
      unsetLoader();
      toaster({ text: `Error fetching data: ${error}` }, { type: "error" });
    }
  };

  if (!isLoggedIn) return <Signin />;

  return (
    <div>
      {userType === "admin" && (
        <Box textAlign="center">
          <Box>
            <p style={{ fontSize: "36px" }}>
              Leads In: {totalLeadsIn.toLocaleString("en-US")} - Leads Out:{" "}
              {totalLeadsOut.toLocaleString("en-US")} - Revenue:{" "}
              {totalRevenue.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              €
            </p>
          </Box>

          <Box>
            <BarChart
              width={1600}
              height={600}
              data={data}
              margin={{
                top: 80,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend
                formatter={(value, entry, index) => {
                  if (value === "input") return "Leads In";
                  if (value === "output") return "Leads Out";
                  return value;
                }}
              />
              <Bar
                dataKey="input"
                fill="#3882f6"
                activeBar={<Rectangle fill="#3882f6" stroke="#3882f6" />}
              />
              <Bar
                dataKey="output"
                fill="#37bc9c"
                activeBar={<Rectangle fill="#37bc9c" stroke="#37bc9c" />}
              />
              <text x={800} y={40} textAnchor="middle" fontSize={20}>
                {" "}
                {/*fontWeight="bold"*/}
                Leads In / Leads Out Comparison by Campaign
              </text>
            </BarChart>
          </Box>
          <Box sx={{ marginBottom: "50px" }}>&nbsp;</Box>
          <Box>
            <LineChart
              width={1600}
              height={600}
              data={lineChartData}
              margin={{
                top: 80,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis dataKey="value" />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="value"
                stroke="#37bc9c"
                strokeWidth={2}
              />
              <text x={800} y={40} textAnchor="middle" fontSize={20}>
                Current Year Leads Out Trend
              </text>
            </LineChart>
          </Box>
          <Box sx={{ marginBottom: "50px" }}>&nbsp;</Box>
          <Box>
            <LineChart
              width={1600}
              height={600}
              data={lineChartRevenueData}
              margin={{
                top: 80,
                right: 30,
                left: 20,
                bottom: 100,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" angle={-45} textAnchor="end" />
              <YAxis dataKey="value" />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="value"
                stroke="#37bc9c"
                strokeWidth={2}
              />
              <text x={800} y={40} textAnchor="middle" fontSize={20}>
                Current Month Revenue Trend
              </text>
            </LineChart>
          </Box>
        </Box>
      )}
    </div>
  );
}
