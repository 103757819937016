/* eslint-disable no-undef */
import axios from "axios";
import { LOCALSTORAGE_KEY_TOKEN } from "../AuthContext";

const API = () => {
  const defaultOptions = {
    baseURL: "https://lead-me-hard.rankit.it",
    //baseURL: "http://localhost:5000",
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  }

  function removeCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.blackboxgreen.com`;
  }

  let instance = axios.create(defaultOptions);
  // API Request Interceptores
  instance.interceptors.request.use(
    (apiConfig) => {
      const localStorageData = localStorage.getItem(LOCALSTORAGE_KEY_TOKEN);
      const userData = JSON.parse(localStorageData);
      //const userData = JSON.parse(getCookie('rankit_token'));
      //const userData_ = getCookie('rankit_token')
      //console.log(userData_)
      //let userData = userData_.split("***")
      //console.log('api')
      const authToken = userData["token"];
      //console.log(authToken)

      if (authToken) {
        apiConfig.headers[LOCALSTORAGE_KEY_TOKEN] = `${authToken}`;
      }

      return apiConfig;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      console.log(error)
      if (error?.response?.status === 401) {
        localStorage.removeItem(LOCALSTORAGE_KEY_TOKEN);
        removeCookie("token");
        //window.location.assign("/signin");
        window.location.assign("https://www.rankit.it")
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export default API();
