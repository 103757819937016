import React, { useEffect } from "react";
import Layout from "../Layout";
import { useAuth } from "../AuthContext";
import { redirect } from "react-router-dom";
import Signin from "../Signin";

const Protected = ({ Component }) => {
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    !isLoggedIn && redirect("/signin");
  }, [isLoggedIn]);

  if (!isLoggedIn) return <Signin />;

  return (
    <Layout>
      <Component />
    </Layout>
  );
};

export default Protected;
