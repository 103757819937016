// AuthContext.js
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";


const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const apiUrl = "https://lead-me-hard.rankit.it";

export const LOCALSTORAGE_KEY_TOKEN = "token";

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState("");
  const [userType, setUserType] = useState("");
  const [token, setToken] = useState("");
  const [userName, setUserName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [apiUrl, setApiUrl] = useState("https://lead-me-hard.rankit.it");
  //const [apiUrl, setApiUrl] = useState('http://localhost:5000')
  const navigate = useNavigate();

  const login = (userData) => {
    setIsLoggedIn(true);
    setUserType(userData.type);
    setUserName(userData.username);
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  }

  function removeCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.blackboxgreen.com`;
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=localhost`;
  }

  // add cookie
  // name: 'token', value: '{"partner_code":"DIST002","token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNjYzNjA1ODg4OGI5NjBhZDNkZmIwOGU1IiwiZW1haWwiOiJtYXJjby5waWNjaW9saS4xODk3N0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IlNmWmRmS0hMSEdNdFRqWVkiLCJ0eXBlIjoicGFydG5lciIsInBhcnRuZXJfY29kZSI6IkRJU1QwMDIiLCJleHAiOjE3MTUwNjk4OTB9.xSANho7yj0oiKpkM8o-tFMIrb1pmsKc2201UC_cwSBA","user_id":"6636058888b960ad3dfb08e5","user_type":"partner"}'
  // path: '/', expiry: 3 months, httpOnly: false
  useEffect(() => {
    console.log('here1')
    const localStorageData = localStorage.getItem(LOCALSTORAGE_KEY_TOKEN);
    const userData = JSON.parse(localStorageData);
    console.log(userData)
    //const userData = JSON.parse(getCookie('rankit_token'));
    //const userData_ = getCookie('rankit_token')
    if (!userData) return;
    setIsLoggedIn(true);
    //setUserId(userData[2]);
    setUserType(userData["type"]);
    setToken(userData["token"]);
    setUserName(userData["username"]);
    console.log(userData)
  }, []);

  const logout = () => {
    setIsLoading(false);
    setIsLoggedIn(false);
    //setUserId("");
    setUserType("");
    setToken("");
    setUserName("");
    removeCookie("token");
    localStorage.removeItem(LOCALSTORAGE_KEY_TOKEN);
    navigate("/signin");
    //window.location.href = "https://rankit.it/signin";
  };

  const setLoader = () => {
    setIsLoading(true);
  };

  const unsetLoader = () => {
    setIsLoading(false);
  };

  const value = useMemo(
    () => ({
      isLoggedIn,
      userId,
      userType,
      token,
      userName,
      login,
      logout,
      apiUrl,
      isLoading,
      setLoader,
      unsetLoader,
    }),
    [isLoggedIn, userId, token, isLoading, userName]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
