import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Protected from "./components/Protected";
import Signin from "./Signin";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import Home from "./Home";
import Campaigns from "./Campaigns";
import CampaignsCreate from "./CampaignsCreate";
import CampaignsEdit from "./CampaignsEdit";
import Leads from "./Leads";
import Opportunities from "./Opportunities";
import OpportunitiesCreate from "./OpportunitiesCreate";
import OpportunitiesEdit from "./OpportunitiesEdit";

const Routing = () => {
  return (
    <Routes>
      <Route path="/signin" element={<Signin />} />
      <Route path="/forgot_password" element={<ForgotPassword />} />
      <Route path="/reset_password" element={<ResetPassword />} />
      <Route path="/" element={<Protected Component={Home} />} />
      <Route
        path="/campaigns"
        element={<Protected Component={Campaigns} />}
      />
      <Route
        path="/campaigns/create"
        element={<Protected Component={CampaignsCreate} />}
      />
      <Route
        path="/campaigns/edit/:id"
        element={<Protected Component={CampaignsEdit} />}
      />
      <Route
        path="/leads/:id"
        element={<Protected Component={Leads} />}
      />
      <Route
        path="/opportunities"
        element={<Protected Component={Opportunities} />}
      />
      <Route
        path="/opportunities/create"
        element={<Protected Component={OpportunitiesCreate} />}
      />
       <Route
        path="/opportunities/edit/:id"
        element={<Protected Component={OpportunitiesEdit} />}
      />
      
    </Routes>
  );
};

export default Routing;
