import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { LOCALSTORAGE_KEY_TOKEN, useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import Toast, { useToast } from "./components/Toast";

const defaultTheme = createTheme();

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#3882f6",
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#3882f6", // Change the color of the focused label
  },
  "& .MuiInputLabel-root": {
    color: "#000000", // Change the color of the focused label
  },
});

const StyledLink = styled(Link)({
  textDecoration: "none", // Remove underline
});

export default function SignIn({ pluto }) {
  const { isLoggedIn, login, logout, apiUrl } = useAuth();
  const toaster = useToast();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  // const [error, setError] = useState(null);

  function setCookie(name, value, domain, path, secure, httpOnly) {
    let cookieString = `${name}=${value}; domain=${domain}; path=${path}`;


    if (secure) {
      cookieString += "; secure";
    }

    if (httpOnly) {
      cookieString += "; HttpOnly";
    }

    document.cookie = cookieString;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/signin`, {
        username,
        password,
        otp
      });
      console.log(response.data.message)
      if (response.data.message == "ok") {
        login(response.data.data);
        localStorage.setItem(
          LOCALSTORAGE_KEY_TOKEN,
          JSON.stringify(response.data.data)
        );
        //setCookie("token", JSON.stringify(response.data.data), ".blackboxgreen.com", "/", false, false);
        //navigate("/");
      } else {
        // {
        //   <Toast type={"error"} text={"login-failed"} />;
        // }
        // toast(<Toast type={"info"} text={"Hello"} />);
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "error" }
        );
        // toast(() => <div>This is the message to display</div>);
        // toast.error(response.data.data, { toastId: "login-failed-toast" });
        // setError({ type: "error", message: "Login Failed" });
        //alert(response.data.data)
      }
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn]);

  return (
    <Container component="main" maxWidth="xs">
      {/* {error?.message && <Toast type={error.type} text={error.message} />} */}
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
       <img
          src="/images/rankit.png"
          alt="Logo"
      />

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <StyledTextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            onChange={(e) => setUsername(e.target.value)}
          />
          <StyledTextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <StyledTextField
            margin="normal"
            required
            fullWidth
            name="otp"
            label="OTP"
            type="otp"
            id="otp"
            autoComplete="current-password"
            onChange={(e) => setOtp(e.target.value)}
          />
          {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
        />*/}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            style={{ backgroundColor: "#3882f6" }}
          >
            Signin
          </Button>
          {/* <Grid container>
              <Grid item xs>
                <StyledLink href="/forgot_password" variant="body2" style={{"color": '#050947','textUnderline':'none'}}>
                  Password Dimenticata?
                </StyledLink>
              </Grid>
            </Grid>*/}
        </Box>
      </Box>
      <ToastContainer
        hideProgressBar
        icon={<></>}
        closeButton={false}
        toastStyle={{ background: "transparent", boxShadow: "none" }}
      />
    </Container>
  );
}
