import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import Signin from "./Signin"; 
import { useAuth } from "./AuthContext";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "./config/API";
import { useToast } from "./components/Toast";
import { useNavigate } from "react-router-dom";

export default function OpportunitiesCreate() {
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([])
  const {
    isLoggedIn,
    userId,
    token,
    userType,
    partnerCode,
    apiUrl,
    isLoading,
    setLoader,
    unsetLoader,
  } = useAuth();
  const [formData, setFormData] = useState({
    name: "",
    campaign_id: "",
    start_date: "",
    end_date: "",
    cpl: ""
  });
  const toaster = useToast();

  const handleStartDateChange = (date) => {
    if (date) {
      const formattedDate = moment(date["$d"]).format("YYYY-MM-DD");
      setFormData((prevData) => ({
        ...prevData,
        new_date: formattedDate,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        new_date: "", // Reset the expiry_date if date is not provided
      }));
    }
  };

  const handleEndDateChange = (date) => {
    if (date) {
      const formattedDate = moment(date["$d"]).format("YYYY-MM-DD");
      setFormData((prevData) => ({
        ...prevData,
        new_date: formattedDate,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        new_date: "", // Reset the expiry_date if date is not provided
      }));
    }
  };


  const formatDate = (_date) => {
    //console.log(_date)
    let d = moment(_date).format("DD/MM/YYYY");
    //console.log(d)
  };

  const fetchCampaigns = async () => {
    try {
      setLoader();
      if (isLoggedIn == false) {
        return false;
      }
      const url = `/campaigns/null/null`
      const response = await API.get(url);
      unsetLoader();
      console.log(response.data.data)
      setCampaigns(response.data.data);
    } catch (error) {
      unsetLoader();
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
  }

 

  // Handler for form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      for (const key in formData) {
        if(key != 'expiry_period_custom') {
        if (!formData[key]) {
          toaster(
            {
              text: `${key} non puo' essere vuoto!`,
            },
            { type: "error" }
          );
          return false;
        }
      }
       
      }
      setLoader();
      console.log(formData);
      //return false;
      const response = await API.post(`/partners`, formData);
      console.log(response.data); // Handle success
      if (response.data.message == "ok") {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "success" }
        );
        setTimeout(() => {navigate("/opportunities");}, 1000);
      } else {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "error" }
        );
      }
      
    } catch (error) {
      unsetLoader();
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
    
  };


  

  const handleChange = (event) => {
    
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);


  if (!isLoggedIn) return <Signin />;

  return (
    <div style={{ height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <h1>New Opportunity</h1>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name" 
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="partners">Campaign</InputLabel>
                  <Select
                    labelId="subpartners"
                    name="campaign_id"
                    value={formData.campaign_id}
                    onChange={handleChange}
                  >
                    {campaigns.map(campaign => (
                    <MenuItem key={campaign._id} value={campaign._id}>
                      {campaign.name}
                    </MenuItem>
                  ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="CPL" 
                  name="cpl"
                  value={formData.cpl}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  fullWidth
                  label="Start Date"
                  name="start_date"
                  format="DD/MM/YYYY"
                  value={formatDate(formData.new_date)}
                  onChange={handleStartDateChange}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  fullWidth
                  label="End Date"
                  name="end_date"
                  format="DD/MM/YYYY"
                  value={formatDate(formData.new_date)}
                  onChange={handleEndDateChange}
                />
              </Grid>
            <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ backgroundColor: "#3882f6", color: "white" }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Box>
      <ToastContainer
        hideProgressBar
        icon={<></>}
        closeButton={false}
        toastStyle={{ background: "transparent", boxShadow: "none" }}
      />
    </div>
  );
}
