import * as React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CircularProgress from "@mui/material/CircularProgress";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { mainListItems, partnerListItems } from "./listItems";
import { useAuth } from "./AuthContext";
import { Link, useNavigate } from "react-router-dom";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

function Layout(props) {
  const { logout, token, userType, isLoading, setLoader, unsetLoader } =
    useAuth();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();

  console.log(isLoading);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    unsetLoader();
    logout();
    navigate("/signin");
  };

  return (
    <Box
      className={open ? "boxOpen" : ""}
      sx={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <CssBaseline />
      <AppBar
        className="appBar"
        position="fixed"
        open={open}
        style={{ backgroundColor: "#3882f6" }}
      >
        <Toolbar className="toolBar">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
            style={{ color: "#FFFFFF" }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h5"
            noWrap
            component={Link}
            to="/"
            color="inherit"
            sx={{ flexGrow: 1, textDecoration: "none" }}
            style={{ fontWeight: "bold", color: "#ffffff" }}
          >
            LeadStream
          </Typography>
          {isLoading && (
            <div>
              <CircularProgress size={40} style={{ color: "#ffffff" }} />
            </div>
          )}
        <IconButton
            color="inherit"
            style={{ color: "#ffffff" }}
            onClick={handleLogout}
          >
            {/*<Badge badgeContent={4} color="secondary">*/}
           <LogoutIcon />
          {/*  </Badge>*/}
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        className="mainDrawer"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader
          className="drawerHeader"
          style={{ backgroundColor: "#3882f6" }}
        >
          <IconButton
            onClick={handleDrawerClose}
            style={{ backgroundColor: "white" }}
          >
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon style={{ backgroundColor: "white", color: "#3882f6" }} />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {userType === "admin" && <List>{mainListItems}</List>}
      </Drawer>

      <Main
        className="mainContent"
        style={{ overflow: "auto", width: "100%", height: "100%" }}
        open={open}
      >
        <DrawerHeader />
        <Box sx={{ height: `calc(100% - ${drawerWidth}px)` }}>
          {props.children}
        </Box>
      </Main>
      {/* <div className="overlay" onClick={handleDrawerClose}></div> */}
    </Box>
  );
}

export default Layout;
