import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from "react-toastify";

export default function CustomizedSnackbars({
  type,
  text,
  closeToast: handleClose,
}) {
  return (
    <div>
      {/* <Button onClick={handleClick}>Open Snackbar</Button> */}
      {type == "info" && (
        <Alert
          onClose={handleClose}
          severity="info"
          variant="filled"
          sx={{
            width: "100%",
            backgroundColor: "#6397FA", //"#eff5ff",
            border: "1px solid  #6397FA",
            color: "#255FDD",
          }}
          iconMapping={{
            info: <CheckCircleIcon fontSize="inherit" />,
          }}
        >
          {text}
        </Alert>
      )}

      {type == "success" && (
        // <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{
            width: "100%",
            backgroundColor: "#3882f6",
            border: "1px solid  #3882f6",
            color: "#ffffff",
          }}
          iconMapping={{
            success: <CancelIcon fontSize="inherit" />,
          }}
        >
          {text}
        </Alert>
        // </Snackbar>
      )}

      {type == "error" && (
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{
            width: "100%",
            backgroundColor: "#fceaeb",
            border: "1px solid  #DE2E30",
            color: "#9D2B2B",
          }}
          iconMapping={{
            error: <CancelIcon fontSize="inherit" />,
          }}
        >
          {text}
        </Alert>
      )}
    </div>
  );
}

export const useToast = () => {
  const toaster = (myProps, toastProps) =>
    toast(<CustomizedSnackbars type={toastProps.type} {...myProps} />, {
      ...toastProps,
    });

  return toaster;
};
